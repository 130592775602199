<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoMenu"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id_modulo"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Menus</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="1282"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!--  <v-btn icon v-can-access="1283" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
        v-can-access="1285"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="1283"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1285"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadMenu
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editMenu="menu"
      @close="refresh()"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadMenu from "@/components/sistema/menus/ModalCadMenu";

export default {
  name: "ListaMenus",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadMenu,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      search: "",
      infoMenu: [],
      menu: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Menu", value: "descricao" },
        { text: "Módulo", value: "modulo" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchMenus() {
      this.loading = true;
      let { data } = await sistema()
        .menus()
        .show({
          per_page: -1
        });
      this.infoMenu = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.menu = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar menu",
          `Deseja deletar o menu ${item.descricao}?`
        );
        sistema()
          .menus(item.id)
          .delete({}, { notification: true, message: "Menu deletado" });
      } catch (error) {
        console.log(error);
      }
    },

    refresh() {
      this.dialog = false;
      this.fetchMenus();
    }
  },

  mounted() {
    this.fetchMenus();
  }
};
</script>
