<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoModulo"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="nome"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Módulos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="1287"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon v-can-access="1288" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="1290"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="1288"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1290"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadModulo
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editModulo="modulo"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadModulo from "@/components/sistema/modulos/ModalCadModulo";

export default {
  name: "ModuloAcesso",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadModulo,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      search: "",
      infoModulo: [],
      modulo: {},
      headers: [
        { text: "ID", value: "id_modulo" },
        { text: "Módulo", value: "modulo" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchModulos() {
      this.loading = true;
      let { data } = await sistema()
        .modulos()
        .show({
          per_page: -1
        });
      this.infoModulo = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.modulo = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar permissão",
          `Deseja deletar o módulo ${item.modulo}?`
        );
        sistema()
          .modulos(item.id_modulo)
          .delete({}, { notification: true, message: "Módulo deletado" });
      } catch (error) {
        console.log(error);
      }
    }
  },

  mounted() {
    this.fetchModulos();
  }
};
</script>
