<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Módulo" : "Cadastrar Módulo" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                label="Nome"
                v-model="form.modulo"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? editarModulo() : cadastrarModulo()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";
export default {
  name: "ModalCadModulo",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    editModulo: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    };
  },

  watch: {
    editModulo(value) {
      this.form = value;
    }
  },

  methods: {
    async cadastrarModulo() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .modulos()
          .store(this.form, {
            notification: true,
            message: "Módulo cadastrado com sucesso!"
          });
        this.close();
      }
    },

    async editarModulo() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .modulos(this.form.id_modulo)
          .update(this.form, {
            notification: true,
            message: "Módulo editado com sucesso!"
          });
        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
