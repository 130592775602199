<template>
  <v-container class="container-card">
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>
          Módulos
        </v-tab>
        <v-tab>
          Menus
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ModuloAcesso />
        </v-tab-item>
        <v-tab-item>
          <ListaMenus />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ModuloAcesso from "@/components/sistema/modulos/ModuloAcesso";
import ListaMenus from "@/components/sistema/menus/ListaMenus";

export default {
  name: "ConsultaModulo",

  components: {
    ModuloAcesso,
    ListaMenus
  },

  data() {
    return {
      tab: null
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Modulos/Menus");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
